<template>
  <div class="date-of-birth flex flex-column flex--align-center">
    <h2 class="u-typography-helvetica u-text u-text--s q-mb24 q-mt0">
      {{ $t('label.myDateOfBirth') }}
    </h2>
    <div class="date-of-birth-inputs">
      <u-dropdown
        class="date-of-birth-inputs__element"
        :value="formattedYear"
        placeholder="YYYY"
        :options="yearOptions"
        @input="onYearChange"
      />
      <u-dropdown
        class="date-of-birth-inputs__element q-ml24"
        :value="$t(formattedMonth)"
        placeholder="MM"
        :options="monthOptions"
        @input="onMonthChange"
      >
        <template #option="{ option }">
          {{ $t(option) }}
        </template>
      </u-dropdown>
      <u-dropdown
        class="date-of-birth-inputs__element q-ml24"
        :value="formattedDayOfMonth"
        placeholder="DD"
        :options="dateOptions"
        @input="onDateChange"
      />
    </div>
  </div>
</template>

<script>
import { range, isNil } from 'lodash';
import getDaysInMonth from 'date-fns/get_days_in_month';
import subYears from 'date-fns/sub_years';
import format from 'date-fns/format';
import isValid from 'date-fns/is_valid';

import { UDropdown } from 'universkin-shared';

const formatYear = year => (year ? year.toString() : '');

const formatDayOfMonth = day => (day ? day.toString().padStart(2, '0') : '');

const MONTHS = [
  'label.january',
  'label.february',
  'label.march',
  'label.april',
  'label.may',
  'label.june',
  'label.july',
  'label.august',
  'label.september',
  'label.october',
  'label.november',
  'label.december'
];

const formatMonth = month => {
  const monthTitle = MONTHS[month];

  return monthTitle || '';
};

export default {
  name: 'DateOfBirth',
  components: { UDropdown },
  props: {
    /* yyyy-mm-dd */
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    const { year, month, date } = this.getParsedValue();

    return {
      year,
      month,
      date,
      minDate: subYears(new Date(), 120),
      maxDate: subYears(new Date(), 12)
    };
  },
  computed: {
    formattedYear() {
      return formatYear(this.year);
    },
    formattedMonth() {
      return formatMonth(this.month);
    },
    formattedDayOfMonth() {
      return formatDayOfMonth(this.date);
    },
    maxYear() {
      return this.maxDate.getFullYear();
    },
    maxMonth() {
      return this.maxDate.getMonth();
    },
    yearOptions() {
      const minYear = this.minDate.getFullYear();
      const maxYear = this.maxDate.getFullYear();

      return range(minYear, maxYear + 1)
        .map(year => year.toString())
        .reverse();
    },
    monthOptions() {
      if (isNil(this.year)) {
        return [];
      }

      if (this.year === this.maxYear) {
        return range(0, this.maxMonth + 1).map(formatMonth);
      }

      return range(0, 12).map(formatMonth);
    },
    dateOptions() {
      if (isNil(this.year) || isNil(this.month)) {
        return [];
      }

      if (this.year === this.maxYear && this.month === this.maxMonth) {
        return range(1, this.maxDate.getDate() + 1).map(formatDayOfMonth);
      }

      return range(1, getDaysInMonth(new Date(this.year, this.month)) + 1).map(formatDayOfMonth);
    }
  },
  watch: {
    month: 'onDateOfBirthChange',
    date: 'onDateOfBirthChange',
    year: 'onDateOfBirthChange'
  },
  methods: {
    onDateOfBirthChange() {
      const value = this.getFormattedDate();

      this.$emit('input', value);
    },
    getParsedValue() {
      if (!this.value) {
        return {};
      }

      const [year, month, date] = this.value.split('-');

      return { year: +year, month: +month - 1, date: +date };
    },
    onMonthChange(month) {
      this.month = MONTHS.indexOf(month);
    },
    onDateChange(date) {
      this.date = +date;
    },
    onYearChange(year) {
      this.year = +year;
    },
    getFormattedDate() {
      const date = new Date(this.year, this.month, this.date);

      if (isValid(date)) {
        return format(new Date(this.year, this.month, this.date), 'YYYY-MM-DD');
      }

      return '';
    }
  }
};
</script>

<style lang="scss" scoped>
.date-of-birth-inputs {
  display: flex;
  width: 100%;

  &__element {
    flex: 1 1 0;
  }
}
</style>
